<section>
  <div class="main">
    <h3 data-aos="fade-right" data-aos-duration="1000">Skills</h3>
    <p class="skill-set-text">
      {{ "skillSetText" | translate }}
    </p>
    <div class="skill-set">
      <div class="skill-icons">
        <div class="skill-icon" *ngFor="let skill of skillSet; let i = index">
          <img
            src="./assets/img/icons/skillset/desktop/{{ skillSet[i].icon }}"
            alt=""
          />
          <p>{{ skillSet[i].name }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
