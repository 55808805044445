<section>
  <div class="main">
    <div class="header-about">
      <h3>Portfolio</h3>
      <p>{{ "portfolioDescription" | translate }}</p>
    </div>

    <div class="projects">
      <div
        class="project"
        *ngFor="let project of projects; let i = index"
        [ngStyle]="{
          'flex-direction': i % 2 === 0 ? 'row' : 'row-reverse'
        }"
      >
        <div class="project-img" data-aos="fade-right" data-aos-duration="1000">
          <img
            [src]="'./assets/img/pc_mockup/' + projects[i].mockup"
            [alt]="projects[i].mockup"
          />
        </div>

        <div
          class="project-text"
          [ngClass]="{ even: i % 2 === 0, odd: i % 2 !== 0 }"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <h4>
            {{ projects[i].name }}
          </h4>
          <p>{{ projects[i].projectLanguage }}</p>
          <p>{{ projects[i].description | translate }}</p>

          <div class="project-text-buttons">
            <a href="{{ projects[i].liveTest }}" target="_blank">
              <button class="fill-button">
                {{ "liveTest" | translate }}
              </button></a
            >
            <a
              href="https://github.com/christian-grund/{{ projects[i].link }}"
              target="_blank"
            >
              <button class="fill-button">Github</button></a
            >
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngFor="let circle of circles">
      <div class="circle-{{ circle }}">
        <img
          [src]="'./assets/img/background/ball.svg'"
          [alt]="'Ball ' + circle"
        />
      </div>
    </ng-container>
  </div>
</section>
